import React, { useEffect, useState } from 'react'
import api from '../../axiosInstance';
import { BaseURL } from '../Masters/masterPagefunctions';
import { useQuery } from '@tanstack/react-query';
import syncimage from '../../asset/sync.png'




function DashboardCard({ item, bgimage, lastRefetchTimeParentms }) {


    const fetchDashboardCard = async () => {
        const { data } = await api.post(`https://${item?.apiservicename}.${BaseURL}${item?.apiendpoint}`, {
            userid: 1,
            api: `${item?.apiendpoint}`,
            apiargument: `${item?.apiarguments?.[0]}`,
        });

        return data;
    };





    const { data, error, isLoading, isFetching, dataUpdatedAt, refetch } = useQuery({
        queryKey: ["dashboardValues", item?.apiendpoint, item?.apiarguments?.[0], item?.refreshinterval],
        queryFn: fetchDashboardCard,
        // refetchInterval: item?.refreshinterval * 1000,
        // refetchOnWindowFocus: true,
    })






    const lastRefetchTimeChild = dataUpdatedAt
        ? new Date(dataUpdatedAt).toLocaleTimeString()
        : "No refetch yet";

    const diffrents = lastRefetchTimeParentms - dataUpdatedAt

    let trueconsition

    if (item?.autorefresh) {
        trueconsition = diffrents > item?.refreshinterval * 1000
    }

    useEffect(() => {
        refetch()
    }, [trueconsition])



    function valueunits(value) {
        // debugger
        if (value >= 1e9) {
            return (value / 1e9).toFixed(1) + 'B';
        } else if (value >= 1e6) {
            return (value / 1e6).toFixed(1) + 'M';
        } else if (value >= 1e3) {
            return (value / 1e3).toFixed(1) + 'K';
        }
        return value;
    }

    const formatNumber = (value, format) => {
        // debugger
        if (!value || typeof format?.dataformat !== "string") return value?.value;

        let [integerPart, decimalPart] = value?.value?.toString()?.split(".");


        let decimalPlaces = format?.dataformat?.includes(".") ? format?.dataformat?.split(".")[1]?.length : 0;


        let groups = format?.dataformat?.split(".")[0]?.split(",")?.reverse()?.map(g => g.length);


        let formattedInteger = "";
        let number = integerPart?.split("")?.reverse();
        let index = 0;

        for (let i = 0; i < groups.length && number.length > 0; i++) {
            if (i > 0) formattedInteger = "," + formattedInteger; // Add comma
            formattedInteger = number?.splice(0, groups[i])?.reverse()?.join("") + formattedInteger;
        }

        // If any digits remain, add them at the beginning
        if (number.length > 0) formattedInteger = number?.reverse()?.join("") + "," + formattedInteger;

        // Format decimal part
        decimalPart = decimalPart ? decimalPart?.substring(0, decimalPlaces)?.padEnd(decimalPlaces, "0") : "0".repeat(decimalPlaces);
        let finalValue = decimalPlaces ? `${formattedInteger}.${decimalPart}` : formattedInteger;
        return valueunits(finalValue)
    };






    // console.log("widget card", item?.apiarguments?.[0]);


    if (isLoading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;
    return (
        <div className="relative">
            <img src={bgimage?.image} className="absolute top-[-22px]" alt={item?.name} width={50} srcset="" />
            <div>
                <div>
                    <h2 className="text-center pt-3">{item?.label ? item?.label : item?.name}</h2>
                    <div>
                        {data?.data?.length > 0 ? (
                            data.data.map((cardItems, index) => (
                                <h6 key={index} className="text-center" style={{ fontSize: "28px", color: `${bgimage?.color}` }}>
                                    {formatNumber(cardItems, item)}{/* Default to 0 if value is missing */}
                                </h6>

                            ))
                        ) : (
                            <h6 className="text-center" style={{ fontSize: "28px", color: `${bgimage?.color}` }}>0</h6> // Show zero when there's no data
                        )}
                        <div className='flex justify-end items-center px-1'>
                            {item?.showsynctime && <label className='p-1' style={{ fontSize: '12px' }}>Syn Time : {lastRefetchTimeChild}</label>} {item?.allowsync && <button style={{ fontSize: '10px' }} onClick={() => refetch()}> <img src={syncimage} className={isFetching ? 'animate-spin' : ''} alt="" srcset="" width={15} /> </button>}
                        </div>
                    </div>

                </div>


            </div>

        </div>
    )
}

export default DashboardCard