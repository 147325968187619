import { useState } from 'react';
import api from '../axiosInstance';
const useFetchTaxes = (setLogoutModalExRefreshToken) => {
  const [taxes, setTaxes] = useState([]);
  const [taxRate, setTaxRate] = useState(null);
  const [arrtax, setarrtax] = useState([])
  const [onetaxrow, setonetaxrow] = useState()
  const fetchTaxes = async (nurl,value,name,taxsingle) => {
    
    let url 
    if(nurl){
    url=  nurl.replace(":"+name,value)
    }


    console.log("fetchTaxes url",url)

    try {
        const response = await api.get(url);

      const taxdata = response.data.data;
    
      if(taxdata){
      // setarrtax([...arrtax,{ [name]:value, txntax_id:taxdata.id, txn_taxrate:taxdata.taxrate} ])
      setarrtax((prev) => [
        ...prev,
        { [name]: value, txn_taxid: taxdata.id, txn_taxrate: taxdata.taxrate },
      ]);
      }

      // settaxacc(taxarr)
      console.log("rrtax",arrtax)

      if (taxdata.taxrate) {
        setTaxRate(taxdata.taxrate);
      }

      if(taxsingle){
        setonetaxrow(taxsingle)
      }
      // setTaxes(taxarr);
      // return taxarr
    } catch (err) {
      console.error('Error fetching taxes:', err);
    }
  };
  
  return { fetchTaxes, taxes, taxRate,arrtax ,onetaxrow, setonetaxrow };
};

export default useFetchTaxes;
