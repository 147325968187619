import { BaseURL} from "../Masters/masterPagefunctions";
import api from "../../axiosInstance";





export const getMenuItems = async (setMenuItems, setLogoutModalExRefreshToken) => {
  const userid = sessionStorage.getItem('userid');
  try {
    const response = await api.get(
      `https://operations.${BaseURL}/api/menus/${userid}`);

    if (response.status === 200) {
      console.log("Menu data", response.data);
      setMenuItems(response.data)
    } else {
      console.error("Request failed with status:", response.status);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};