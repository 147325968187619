import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { Table, TextInput, Slider, Checkbox ,NumberInput,Loader} from "@mantine/core";
import "./billallocation.css"
import { BaseURL } from "../Masters/masterPagefunctions";
import api from "../../axiosInstance";

const BillAllocation= React.memo(({ txnId, modaltxn,onConfirm})=> {

  const [billData, setBillData] = useState([])
  const [loading, setLoading] = useState(true)
  const [selectedRows, setSelectedRows] = useState(new Set());
  const [advance, setAdvance] = useState(0); // Separate state for manual input
  const [totalWithAdvance, setTotalWithAdvance] = useState(0); 


  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const res = await api.get(
          `https://transactionhub.${BaseURL}/api/v1/bills?accid=${txnId}`
        );
        console.log("rr", res);
        const fetchedData = res?.data?.data.map((item) => ({
          ...item,
          initialSettledAmount: item.settledamount?item.settledamount:0, // Store initial value from API
          settledamount:item.settledamount>0?0:item.settledamount,
        }));

        // const fetchedData = billData?.map((item) => ({
        //   ...item,
        //   initialSettledAmount: item.settledamount?item.settledamount:0, // Store initial value from API
        //   settledamount:item.settledamount>0?0:item.settledamount,

        // }));
        console.log("fetchedData",fetchedData)
        setBillData(fetchedData);
        // setBillData(res?.data?.data)
      } catch (err) {
        console.log(err);
      } finally{
        setLoading(false)
      }
    };

    fetchData();
  }, [txnId]);

  // Handle slider change
  const handleSliderChange = (value) => {
    setBillData((prevData) => {
      const newData = [...prevData];
      let remainingValue = value;

      // Use selected rows if any, otherwise use all rows
      const targetRows = selectedRows.size > 0
        ? newData.filter((_, index) => selectedRows.has(index))
        : newData;

      for (let i = 0; i < targetRows.length; i++) {
        const currentItem = targetRows[i];

        if (remainingValue >= currentItem.billedamount) {
          if(currentItem.initialSettledAmount>0){
            currentItem.settledamount = currentItem.billedamount-currentItem.initialSettledAmount;
          
            remainingValue -= currentItem.billedamount-currentItem.initialSettledAmount;
          }
          else {
          currentItem.settledamount = currentItem.billedamount;
          remainingValue -= currentItem.billedamount;
          }
        } else {
          currentItem.settledamount = remainingValue;
          remainingValue = 0;
        }

        // currentItem.balance = currentItem.billedamount - currentItem.settledamount;
        if (currentItem.initialSettledAmount > 0) {
          currentItem.balance =currentItem.billedamount - currentItem.initialSettledAmount - currentItem.settledamount;
        } else {
          currentItem.balance = currentItem.billedamount - currentItem.settledamount;
        }
      }

      return newData;
    });
  };

  const resetUnselectedRows = () => {
    setBillData((prevData) => {
      const newData = prevData.map((item, index) => {
        if (!selectedRows.has(index)) {
          return {
            ...item,
            settledamount: 0, // Reset to original value
            balance: item.billedamount, // Reset balance to original settledamount
          };
        }
        return item;
      });
      return newData;
    });
  };


  // Calculate the total settled amount for all rows or selected rows
  const totalSettledAmount = selectedRows.size > 0
    ? [...selectedRows].reduce((acc, index) => acc + billData[index].settledamount, 0)
    : billData.reduce((acc, item) => acc + item.settledamount, 0);

  // Calculate the total billed amount (sum of all billed amounts)
  let totalBilledAmount = selectedRows.size > 0
    ? [...selectedRows].reduce((acc, index) => acc + (billData[index].billedamount || 0), 0)
    : billData.reduce((acc, item) => acc + (item.billedamount || 0), 0);
  
  


  // Handle checkbox selection
  const handleCheckboxChange = useCallback((index) => {
    setSelectedRows((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(index)) {
        newSelected.delete(index);
        setBillData((prevData)=> {
          const newData = [...prevData] 
          newData[index].settledamount = 0 
          newData[index].balance = newData[index].billedamount;
          return newData
        })
      } else {
        newSelected.add(index);
      }
      resetUnselectedRows();
      return newSelected;
    });
  },[]);

  

  const handleSettledAmountChange = (index, value) => {
    setBillData((prevData) => {
      const newData = [...prevData];
      if (index < 0 || index >= newData.length) {
        console.warn(`Index ${index} is out of bounds.`);
        return prevData; // Return previous state if index is invalid
      }
      newData[index].settledamount = value ? parseFloat(value) : 0;  // Update settledamount in state
      if( newData[index].initialSettledAmount>0){
      newData[index].balance = newData[index].billedamount -newData[index].initialSettledAmount-newData[index].settledamount; 
      }
      else {
        newData[index].balance = newData[index].billedamount - newData[index].settledamount; 
  
      }
      // Recalculate balance
      
     
      
      return newData;
    });
  };

  const handleAdvanceChange = useCallback((value) => {
    const numericValue = Number(value); // Ensure the value is a number
    if (!isNaN(numericValue)) {
      setAdvance(numericValue); // Update only if it's a valid number
    }
  },[]);

  useEffect(() => {
    setTotalWithAdvance(totalSettledAmount + advance);
  }, [totalSettledAmount, advance]);


  console.log("billdata",billData)

  return (
    <div >
      {
        loading?(
          <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
             <Loader size="xs"  />
            </div>
         
        )
        :
      (
        <>
      {billData && billData.length>0 ?(
        <>
      <div className="table-wrapper">
      <div>
      <Table >
        <thead>
          {billData && (
            <tr  className="table-header">
              <th className="table-header-content"></th>
              <th style={{color:"white"}}> Bill Details</th>
              <th style={{color:"white"}}>Amount Details</th>
              {/* <th style={{color:"white"}}>Paid Details</th> */}
              <th style={{color:"white"}}>Payment Details</th>
              <th style={{color:"white"}}>Balance</th>
            </tr>
          )}
        </thead>
        <tbody className="table-container">
          {billData?.map((item, index) => (
            <tr
              key={item.billid}
              style={{ backgroundColor: item.billedamount-item.settledamount==0 || item.balance==0? "#b3d4c3" : "white" ,height:"6px", paddingTop: "10px" }}
            >
              <td>
                <Checkbox
                  checked={selectedRows.has(index)}
                  onChange={() => handleCheckboxChange(index)}
                  size="xs"
                />
              </td>
              <td>
                 <span className="head-label"> Bill No :{item.billno}  </span>
                <br />
                 <span className="head-label">Bill Date :{item.billdate}</span>
                <br />
                <span className="head-label">Due Date :{item.duedate}</span>
              </td>
              <td><span className="head-label">Bill Amount :{item.billedamount} </span></td>
              {/* <td>{item.initialSettledAmount}</td> */}
              <td>
              <div style={{display:"flex"}}>
              <span style={{marginRight:"8px",marginTop:"2px"}} className="head-label">Payment</span>
              <NumberInput
                    styles={{ input: { height: 2 } }}
                  value={item.settledamount}
                  // onChange={handleSettledAmountChange} 
                  onChange={(value) => handleSettledAmountChange(index, value)} 
                  hideControls// Handle input change
                  classNames={{input:'custom-number-input'}}
                  precision={2}
                />
                </div>
              </td>
              <td>{item.balance}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      </div>
      <div style={{display:"flex",justifyContent:"end"}}>
        <div>
          <div style={{display:"flex", alignItems:"center"}}>
          <label style={{marginRight:"20px",marginTop:"-10px",fontSize:"14px"}}><span className="head-label">Advance</span></label>
          <NumberInput
            styles={{ input: { height: 2 } }}
          value={advance}
          onChange={handleAdvanceChange}
          // label="Advance"
          min={0}
          max={totalBilledAmount}
          hideControls
          classNames={{input:'custom-number-input'}}
          precision={2}
            />
          </div>
       
        </div>
      </div>
     </div>
      <div  className="bill-footer">
      <div className="number-input-container" >
       
        
       <NumberInput
         size='xs'
         value={totalWithAdvance}
         classNames={{input:'custom-total-input'}}
         precision={2}
         readOnly
       />
     
     </div>
        <div className="slider-confirm">
        
     
        <Slider
          value={totalSettledAmount}
          onChange={handleSliderChange}
          min={0}
          max={totalBilledAmount}
          step={1} // Adjust step based on your requirements
          label={(val) => `${val}`}
          style={{ width: '170px' }} 
          styles={{
            track: {
              height: '4px',  
            
            },
            bar: {
              backgroundColor: '#453f8f',  // Color of the filled portion
              height: '4px',  // Height of the filled track
            },
            thumb: {
              backgroundColor: '#453f8f',  // Color of the slider thumb
              border: '2px solid #453f8f', // Optional: add a border to the thumb
            },
           
          }}
        />
          <button className="confirm-button" onClick={()=>onConfirm(totalWithAdvance,billData)}>
            Confirm
           </button>
           </div>
         
      
      </div>
      
     
      </>
      ):(
        <div style={{ padding: "20px", textAlign: "center" }}>
          No data found
        </div>
)}

      </>
      )
    }
    </div>
  );
});

export default BillAllocation;
