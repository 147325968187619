import { Box, Button, Flex, Group, Modal, Select } from '@mantine/core';
import { MantineReactTable, useMantineReactTable, MRT_ToggleFiltersButton, MRT_ToggleGlobalFilterButton, MRT_ToggleDensePaddingButton, MRT_ToggleFullScreenButton, MRT_ShowHideColumnsButton, MRT_GlobalFilterTextInput } from 'mantine-react-table';
import { ActionIcon, Tooltip } from '@mantine/core';
import { IconColumns, IconPrinter } from '@tabler/icons-react';
import moment from 'moment';
import numeral from 'numeral';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EmptyData from '../../asset/Menusvg/main/emptydata.png'
import BeforeLoadTableData from '../CommanCoponent/BeforeLoadTableData';
import { BaseURL} from '../Masters/masterPagefunctions';
import { darkTheme, dayTheme } from '../../PageStyle/colorsdarkWhite';
import { updatefield } from '../../utils/helper';
import { useTranslation } from 'react-i18next';
import { Pagination, Stack } from '@mui/material';
import ReportPrintComponent from './ReportPrintComponent';
import templates from './ReportTemplate.json'
import { evaluateExpression } from '../../utils/helper';
// import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { TimePicker } from '@mui/x-date-pickers/TimePicker';
// import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
// import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import './report.css'
import { Label } from '@mui/icons-material';
import CustomDateRangePicker from './CustomDateRangePicker';
import api from '../../axiosInstance';
import axios from 'axios';


function ReportMainCom({ formDetails,hiddenReloadIcon,sethiddenReloadIcon, setLogoutModalExRefreshToken }) {
  const [reports, setReports] = useState([]);
  const [allreportdata, setAllreportdata] = useState([]);
  const [selectedReport, setSelectedReport] = useState(null);
  const [reportData, setReportData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedDateFilter, setSelectedDateFilter] = useState('Today'); // State for selected date filter
  const [tempDateFilter, setTempDateFilter] = useState('Today'); // Temporary state for the Select value
  const dispatch = useDispatch();
  const [activeInactive, setActiveInactive] = useState('active');
  const [menuLoaded, setMenuLoaded] = useState(false);
  const { t } = useTranslation();
  const darkModeState = useSelector((state) => state.darkMode)
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState({
    perpage: '0',
    totalpages: '0',
    totrecocords: '0',
  });
  const [printModalOpened, setPrintModalOpened] = useState(false);
  const selectedTemplate = templates[0];
  const [showModal, setShowModal] = useState(false);
  const [reportFilterButton, setReportFilterButton] = useState('')
  const [calendarOpened, setCalendarOpened] = useState(false);
  const [customDateRange, setCustomDateRange] = useState([null, null]);
  const [dateType, setDateType] = useState(null); // 'From' or 'To'
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [dateRange, setDateRange] = useState([null, null]);
  const dateFilterOptions = [
    { value: 'Today', label: t('today') },
    { value: 'Current Month', label: t('currentmonth') },
    { value: 'Previous Month', label: t('previous') },
    { value: 'Custom', label: t('custom') }
  ];

  let filteredIds
  let apiarguments
  if (reportFilterButton) {
    if (reportFilterButton)
      filteredIds = reports.map(item => item.header.id).filter(id => id === reportFilterButton);
    apiarguments = reports.filter(item => item.header.id === reportFilterButton).map(item => item.header.apiarguments);

    if (reportFilterButton !== filteredIds?.[0]) {
      filteredIds = reports.map(item => item.header.id);
      apiarguments = reports.map(item => item.header.apiarguments);
    } else {
      filteredIds = reports.map(item => item.header.id).filter(id => id === reportFilterButton);
      apiarguments = reports.filter(item => item.header.id === reportFilterButton).map(item => item.header.apiarguments);
    }
  } else {
    filteredIds = reports.map(item => item.header.id);
    apiarguments = reports.map(item => item.header.apiarguments);
  }

  // const [totalPages, setTotalPages] = useState(0);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);
  useEffect(() => {
    if (formDetails) {
      setReports(formDetails);
      if (formDetails.length > 0) {
        setSelectedReport(formDetails[0]); // Select the first report by default
        //fetchReportData(formDetails[0], selectedDateFilter); // Fetch data for the first report with date filter
      } else {
        console.warn('No reports available'); // Debugging log
      }
    }
    setLoading(false);
  }, [formDetails, selectedDateFilter]); // Add formDetails and selectedDateFilter as dependencies to refetch data when they change


  useEffect(() => {
    setReportData([])
    setAllreportdata([])
  }, [formDetails])

  

  const fetchReportData = async (report, dateFilter, page) => {
    try {
      const apiendpoint = report.header.apiendpoint;
      const apiServiceName = report.header.apiservicename;
  
      console.log("servicebname", apiarguments?.[0], apiendpoint, apiServiceName);
      const apiUrl = `https://${apiServiceName}.${BaseURL}${apiendpoint}`;
  
      console.log("apiUrl", apiUrl);
  
      const { fromdate, todate } = handleDateFilter(dateFilter, dateRange);
  
      const params = {
        fromdate,
        todate,
        ...(page > 1 && { page }),
      };
  
      console.log("Request Params:", params);
      setMenuLoaded(true);
  
      const response = await api.get(`${apiUrl}/${apiarguments?.[0]}`, {params,});
  
      console.log('Fetched Report Data:', response.data);
      
      if (response.data && Array.isArray(response.data.data)) {
        const updatedata = updatefield(response.data.data, report);
        setReportData(updatedata);
  
        const totalRecords = response.data.total;
        const recordsPerPage = response.data.per_page;
        const totalPages = Math.ceil(totalRecords / recordsPerPage);
  
        console.log(`Total Records: ${totalRecords}, Records per Page: ${recordsPerPage}, Total Pages: ${totalPages}`);
  
        setTotalRecords({ perpage: recordsPerPage, totrecocords: totalRecords, totalpages: totalPages });
      } else {
        console.error('Fetched data is not in the expected format:', response.data);
        setReportData([]);
      }
    } catch (error) {
      console.error('Error fetching report data:', error);
      setReportData([]);
    } finally {
      setMenuLoaded(false);
    }
  };




 


  

  console.log("Page Object", totalRecords);
  const handleReportClick = (report) => {
    console.log('Report clicked:', report);
    setReportFilterButton(report.header.id)// Debugging log

    if (!report || !report.header) {
      return [];
    }
    console.log("Report s ", report)
    setSelectedReport(report);
    setReportData([])
    setAllreportdata([])

    //fetchReportData(report, selectedDateFilter);
  };

  const handleGetReportClick = () => {
    setSelectedDateFilter(tempDateFilter);
    if (selectedReport) {
      fetchReportData(selectedReport, tempDateFilter);
    }
  };
  const handleDateTypeChange = (value) => {
    setDateType(value);
  };
  const formatData = (value, column) => {
    if (!value || !column.dataformat) return value;
    switch (column.datatype) {
      // case 'Date':
      //   return moment(value).format(column.dataformat.toUpperCase());
      case 'Number': {
        // Clean the input value
        const num = Number(String(value).replace(/,/g, ''));
        if (isNaN(num)) return value;
        console.log("format", column.dataformat);
        // Get decimal places from format
        const decimalPlaces = (column.dataformat.split('.')[1] || '').length;

        // Format number with proper decimals
        const [intPart, decPart] = num.toFixed(decimalPlaces).split('.');

        // Get group sizes from format (counting 0s or #s in each group)
        const groups = column.dataformat.split('.')[0].split(',');
        let formatted = '';
        let remaining = intPart;

        // Apply grouping from right to left
        for (let i = groups.length - 1; i >= 0; i--) {
          const size = groups[i].length;
          if (remaining.length > size) {
            formatted = ',' + remaining.slice(-size) + formatted;
            remaining = remaining.slice(0, -size);
          } else {
            formatted = remaining + formatted;
            remaining = '';
            break;
          }
        }

        // Remove leading comma if present
        formatted = formatted.replace(/^,/, '');
        //console.log("Formated",formatted);
        // Add decimal part
        return decPart ? `${formatted}.${decPart}` : formatted;
      }
      default:
        return value;
    }
  };
  const handleDateFilter = (dateFilter, dateRange = null) => {
    const today = new Date();
    let fromdate, todate;

    switch (dateFilter) {
      case 'Today':
        fromdate = todate = today.toISOString().split('T')[0];
        break;

      case 'Current Month':
        const year = today.getFullYear();
        const month = today.getMonth() + 1;
        fromdate = `${year}-${month.toString().padStart(2, '0')}-01`;
        todate = `${year}-${month.toString().padStart(2, '0')}-${new Date(year, month, 0).getDate()}`;
        break;

      case 'Previous Month':
        fromdate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        fromdate = fromdate.toISOString().split('T')[0];
        todate = new Date(today.getFullYear(), today.getMonth(), 0).toISOString().split('T')[0];
        break;

      case 'Custom':
        if (dateRange) {
          fromdate = moment(dateRange[0]).format('YYYY-MM-DD');
          todate = moment(dateRange[1]).format('YYYY-MM-DD');
        } else {
          fromdate = todate = moment().format('YYYY-MM-DD');
        }
        break;

      default:
        fromdate = todate = today.toISOString().split('T')[0];
    }

    return { fromdate, todate };
  };
  
  



  const fetchAllPages = async (report, dateFilter) => {
    let allData = [];
    let page = 1;
    let hasMorePages = true;

    const checkForSpaces = (value) => {
      return typeof value === 'string' && (value.startsWith(' ') || value.endsWith(' '));
    };

    while (hasMorePages) {
      const apiUrl = `https://${report.header.apiservicename}.${BaseURL}${report.header.apiendpoint}`;
      const { fromdate, todate } = handleDateFilter(dateFilter, dateRange);

      try {

        const response = await api.get(`${apiUrl}/${report.header.apiarguments[0]}`, {
          params: { fromdate, todate, page }});

        const data = response.data; // Axios automatically parses JSON

        if (data && Array.isArray(data.data)) {
          const updatedData = updatefield(data.data, report);
          allData = [...allData, ...updatedData];

          // Check if this is the last page
          if (data.data.length < data.per_page || page >= data.last_page) {
            hasMorePages = false;
          } else {
            page++;
          }
        } else {
          hasMorePages = false;
        }
      } catch (error) {
        console.error('Error fetching report data:', error);
        hasMorePages = false;
      }
    }

    // Apply space checking logic to each row
    const newAllData = allData.map(row => {
      let compRow = { ...row };
      report.columns.forEach(column => {
        if (column.computed && column.expression) {
          compRow[column.columnname] = evaluateExpression(column.expression, row.original, column);
        }

        // Check for spaces in each value and format accordingly
        const hasSpaces = checkForSpaces(row[column.columnname]);
        compRow[column.columnname] = (
          <div style={{ 
            textAlign: column.datatype === 'Number' ? 'right' : 'inherit', 
            width: '100%', 
            whiteSpace: hasSpaces ? 'pre' : 'normal' 
          }}>
            {formatData(row[column.columnname], column)}
          </div>
        );
      });
      return compRow;
    });

    console.log("newAllData", newAllData);
    return newAllData;
};




  

  const columns = useMemo(() => {
    if (!selectedReport) return [];
    console.log("Selectedreport", selectedReport);
    return selectedReport.columns
      .filter(column => column.visible)
      .sort((a, b) => a.position - b.position)
      .map(column => ({
        accessorKey: column.columnname,
        header: column.label,
        size: column.width,
        datatype: column.datatype,
        mantineTableHeadCellProps: {
          align: column.datatype === 'Number' ? 'right' : 'inherit',
        },
        //       enableSorting: false,
        //       enableColumnOrdering: false, // Disables column reordering
        // enableColumnActions: false, // Removes the column actions menu
        // enableColumnFilters: false, // Removes the column filter option
        // enableHiding: false,
        Header: ({ column }) => (

          <h1 style={{ color: 'white', }}>{column.columnDef.header}</h1>
        ),

        Cell: ({ cell, row }) => {
          let value = cell.getValue();
          if (column.computed && column.expression) {
            console.log("Computed", column.expression)
            value = evaluateExpression(column.expression, row.original, column);

          }
          // Check if the value contains leading or trailing spaces
          const hasSpaces = typeof value === 'string' && (value.startsWith(' ') || value.endsWith(' '));
          console.log("hasspace", hasSpaces);
          return (
            <div style={{ textAlign: column.datatype === 'Number' ? 'right' : 'inherit', width: '100%', whiteSpace: hasSpaces ? 'pre' : 'normal' }}>
              {formatData(value, column)}
            </div>
          );
        }
      }));
  }, [selectedReport]);

  const visibleOrderColumns = selectedReport?.columns
    .filter((col) => col.visible)
    .sort((a, b) => a.position - b.position)
  const data = useMemo(() => {
    if (!selectedReport) return [];
    if (!reportData) return [];
    if (visibleOrderColumns.length == 0) {
      return reportData || []
    }
    return reportData.map((row) => {
      const orderedRow = {}
      visibleOrderColumns?.forEach((col) => {
        orderedRow[col.columnname] = row[col.columnname]
      })
      return orderedRow

    })

  }, [selectedReport, reportData])



  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    console.log("New Page", page);
    // Fetch data for the new page
    fetchReportData(selectedReport, selectedDateFilter, page);
  };


  const handlePrint = async () => {
    setPrintModalOpened(true); // Show a loading state in the modal

    try {
      const allData = await fetchAllPages(selectedReport, selectedDateFilter);

      if (selectedTemplate.id === 'template1') {
        selectedTemplate.columns = selectedReport?.columns
          .filter(column => column.visible)
          .map(column => ({
            accessor: column.columnname,
            header: column.label,
            datatype: column.datatype,
            visible: true
          }));
      }

      console.log("All Report Data: ", allData);
      console.log("Selected Template: ", selectedTemplate);

      // Update the state with all fetched data
      setAllreportdata(allData);
    } catch (error) {
      console.error("Error fetching all pages:", error);
      // Handle the error (e.g., show an error message to the user)
    }
  };
  const renderDateFilterModal = () => (
    <Modal
      opened={showModal}
      onClose={closeModal}
      title="Custom Date"
      size="xxl"
      styles={{
        header: {
          background: 'linear-gradient(to right, #5fa1d6, #373195)',
          color: 'white',
        },
        content: {
          background: 'linear-gradient(to bottom, #ffffff, #d4d4d4)',
          borderRadius: '8px',
        },
      }}

    >
      <CustomDateRangePicker
        dateRange={dateRange}
        onChange={(value) => {
          if (value && Array.isArray(value) && value.length === 2) {
            setDateRange(value);
            // Get formatted dates using handleDateFilter
            const { fromdate, todate } = handleDateFilter('Custom', value);

            // Store the custom date range with the formatted dates
            setCustomDateRange({ fromDate: fromdate, toDate: todate });
          }
        }}
        onClose={closeModal}
        onApply={({ fromDate, toDate }) => {
          // Format dates using moment when applying
          const formattedFromDate = moment(fromDate).format('YYYY-MM-DD');
          const formattedToDate = moment(toDate).format('YYYY-MM-DD');

          // Store the custom date range with formatted dates
          setCustomDateRange({
            fromDate: formattedFromDate,
            toDate: formattedToDate
          });

          // Set the filter type to Custom
          setTempDateFilter('Custom');

          // Close the modal
          closeModal();
        }}
        setSelectedDateFilter={setTempDateFilter}
      />
    </Modal>
  );

  const handleDateChange = (value) => {
    setTempDateFilter(value);
    if (value === 'Custom') {
      setShowModal(true);
    }
  };

  const renderDateFilter = () => (
    <div className='flex justify-center items-center mx-1'>
      <Select
        value={tempDateFilter}
        onChange={handleDateChange}
        data={[
          { value: 'Today', label: t('today') },
          { value: 'Current Month', label: t('currentmonth') },
          { value: 'Previous Month', label: t('previous') },
          { value: 'Custom', label: t('custom') },
        ]}
        styles={{
          input: {
            height: '25px',
            minHeight: '25px',
            width: '138px',
            backgroundColor: darkModeState.checkvalue
              ? dayTheme.datefilteroption
              : darkTheme.masterListRowColor,
            color: darkModeState.checkvalue
              ? dayTheme.tableTextColor
              : darkTheme.tableTextColor,
            // Add border styles here
            border: `1px solid ${darkModeState.checkvalue
              ? dayTheme.borderColor
              : darkTheme.borderColor}`,
            borderRadius: '4px',
            fontSize: 'smaller',
          },
          dropdown: {
            backgroundColor: darkModeState.checkvalue
              ? dayTheme.datefilteroption
              : darkTheme.masterListRowColor,
            // Add dropdown border styles
            border: `1px solid ${darkModeState.checkvalue
              ? dayTheme.borderColor
              : darkTheme.borderColor}`,
            borderRadius: '4px',
            // Add box shadow if desired
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          },
          item: {
            '&[data-selected]': {
              '&, &:hover': {
                backgroundColor: darkModeState.checkvalue
                  ? dayTheme.selectedColor
                  : darkTheme.selectedColor,
                color: darkModeState.checkvalue
                  ? dayTheme.tableTextColor
                  : darkTheme.tableTextColor,
              },
            },
            '&[data-hovered]': {
              backgroundColor: darkModeState.checkvalue
                ? dayTheme.hoverColor
                : darkTheme.hoverColor,
            },
          },
        }}
      />
      {renderDateFilterModal()}
    </div>
  );

  const table = useMantineReactTable({
    columns,
    data,
    enableRowSelection: true,
    positionToolbarAlertBanner: 'bottom',
    enableColumnOrdering: true,
    mantineTableContainerProps: { sx: { maxHeight: '59vh', minHeight: '59vh', background: darkModeState.checkvalue ? dayTheme.masterListRowColor : darkTheme.masterListRowColor } },
    initialState: {
      density: 'xs',
      autoResetPageIndex: true,
      pagination: {
        pageIndex: 0,
        pageSize: totalRecords.perpage,
      },
    },

    paginationDisplayMode: 'pages',
    mantinePaginationProps: {
      showRowsPerPage: false,
    },
    mantinePaginationProps: {
      showRowsPerPage: false,
    },
    mantineTableProps: {
      sx: {
        'thead > tr': {
          backgroundColor: '#4B69B6',
        },
        'thead > th': {
          textAlign: 'right'
        },

      }
    },
    mantineTableBodyRowProps: ({ row }) => ({
      selected: row.getIsSelected(),
      sx: {
        backgroundColor: row.getIsSelected()
          ? (darkModeState.checkvalue ? dayTheme.LabelBarColor : darkTheme.LabelBarColor)
          : (darkModeState.checkvalue ? dayTheme.masterListRowColor : darkTheme.masterListRowColor),
        color: row.getIsSelected() ? (darkModeState.checkvalue ? dayTheme.tableTextColor : darkTheme.listhover) : (darkModeState.checkvalue ? dayTheme.tableTextColor : darkTheme.listhover),
        '&:hover': {
          color: darkModeState.checkvalue ? dayTheme.tableTextColor : 'black',
        }
      },
    }),
    mantineTableBodyCellProps: ({ row }) => ({
      sx: {
        background: row.getIsSelected()
          ? (darkModeState.checkvalue ? dayTheme.LabelBarColor : darkTheme.LabelBarColor)
          : (darkModeState.checkvalue ? dayTheme.masterListRowColor : darkTheme.masterListRowColor),
        color: row.getIsSelected() ? '#ffffff' : (darkModeState.checkvalue ? dayTheme.tableTextColor : darkTheme.tableTextColor),
        '&:hover': {
          color: darkModeState.checkvalue ? dayTheme.tableTextColor : 'black',
        }
      },
    }),
    mantineBottomToolbarProps: {
      sx: {
        background: darkModeState.checkvalue ? dayTheme.DmenuCcolor : darkTheme.DmenuCcolor,
      }
    },
    manualPagination: true,
    rowCount: totalRecords.totrecocords,
    onPaginationChange: ({ pageIndex, pageSize }) => {
      handlePageChange(pageIndex + 1);
    },
    renderBottomToolbar: ({ table }) => (
      // <Flex justify="center" align="center" mt="sm">
      //   <Flex gap="xs">
      //     {/* {renderPageButtons()} */}
      //   </Flex>
      // </Flex>
      <Stack spacing={2} sx={{
        mt: 2,
        alignItems: 'self-end',
        backgroundColor: darkModeState.checkvalue ? 'white' : 'rgb(30, 30, 30)',
        padding: '5px 0px 5px 0px'
      }}>
        <Pagination
          count={totalRecords.totalpages} // Dynamic total pages based on data
          page={currentPage} // Dynamic current page

          //color="rgb(166, 163, 201)"
          onChange={handlePageChange} // Handle page change
          variant="outlined"
          shape="rounded"
          siblingCount={1} // Customize number of sibling pages shown
          boundaryCount={1} // Customize number of boundary pages shown
          showFirstButton // Show "First" button
          showLastButton // Show "Last" button
          sx={{
            '& .MuiPaginationItem-root': {
              color: darkModeState.checkvalue ? 'inherit' : 'white',
              borderColor: darkModeState.checkvalue ? 'rgba(0, 0, 0, 0.23)' : 'rgba(255, 255, 255, 0.23)',
            },
            '& .Mui-selected': {
              backgroundColor: darkModeState.checkvalue ? 'rgb(166, 163, 201) !important' : 'rgb(113, 115, 120) !important',
              color: 'white',
              '&:hover': {
                backgroundColor: darkModeState.checkvalue ? 'rgb(146, 143, 181) !important' : 'rgb(93, 95, 100) !important',
              },
            },
          }}
        />
      </Stack>

    ),
    enableColumnOrdering: true,
    paginationDisplayMode: "pages",// Set pagination display mode to pages
    // renderBottomToolbar: ({ table }) => {
    //   console.log('Table State:', table.state); // Debugging: check table state
    //   console.log('Page Count:', table.getPageCount()); // Debugging: check page count
    //   return (
    //     <Box>
    //       <div>Custom Pagination Controls</div>
    //       <Group>
    //         <button onClick={() => table.previousPage()}>
    //           Previous
    //           <table className="getPageCount"></table>
    //         </button>
    //         {Array.from({ length: table.getPageCount() }, (_, index) => (
    //           <button
    //             key={index}
    //             variant={table.getState().pagination.pageIndex === index ? 'filled' : 'outline'}
    //             onClick={() => table.setPageIndex(index)}
    //             color='red'
    //           >
    //             {index + 1}
    //           </button>
    //         ))}
    //         <button onClick={() => table.nextPage()}>
    //           Next
    //         </button>
    //       </Group>
    //     </Box>
    //   )
    // },
    renderEmptyRowsFallback: ({ table }) => (
      <div className='flex justify-center items-center' style={{ height: '49vh' }}>
        <div className='flex justify-center items-center flex-col text-sm'>
          <img src={EmptyData} alt="" srcset="" style={{ width: '200px' }} />
          <h6 className='text-center font-bold text-stone-300 text-lg'>{t('oops')}</h6>
          <p className='text-center font-bold text-stone-300 text-lg'>{t('norecord')}</p>
        </div>
      </div >
    ),

    //positionToolbarAlertBanner: 'top-inside',
    renderTopToolbar: ({ table }) => (
      <Box className='flex justify-between py-2' style={{ backgroundColor: darkModeState.checkvalue ? dayTheme.DmenuCcolor : darkTheme.DmenuCcolor }}>
        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', gap: '8px' }}>
          <div className='sliderContainer' style={{ display: 'flex', alignItems: 'start' }}>
            <div className='filterWrapper' style={{ height: '45px', width: '50vw', overflowX: 'auto', whiteSpace: 'nowrap' }}>
              {reports.map(report => (
                <button
                  key={report.header.id}
                  onClick={() => handleReportClick(report)}
                  className={`${report.header.id === filteredIds?.[0] ? `${darkModeState.checkvalue ? 'filterShadow' : 'filterShadow text-white'}` : 'text-gray-400 border-solid border-2'} filterWidthHieght rounded px-2 py-1 mx-1`}
                  style={{ background: report.header.id === filteredIds?.[0] ? darkModeState.checkvalue ? dayTheme.LabelBarColor : darkTheme.LabelBarColor : '' }}
                >
                  { }
                  {report?.header?.name?.toUpperCase()}


                </button>
              ))}
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', gap: '16px', padding: '4px' }}>
              <button
                className={`${activeInactive === 'active' ? `${darkModeState.checkvalue ? dayTheme.activeButtonColor : darkTheme.activeButtonColor} rounded-full text-white px-1 activeWidthHieght activeShadow` : 'activeWidthHieght rounded'}`}
                onClick={() => setActiveInactive('active')}
              >
                Active
              </button>
              <button
                className={`${activeInactive === 'inactive' ? `${darkModeState.checkvalue ? dayTheme.activeButtonColor : darkTheme.activeButtonColor} rounded-full text-white px-1 activeWidthHieght activeShadow` : 'activeWidthHieght rounded'}`}
                onClick={() => setActiveInactive('inactive')}
              >
                Inactive
              </button>
            </Box>

          </div>
        </div>
        <div className='flex flex-col justify-between'>
          <div className='flex justify-end'>

            <div className='flex'>
              <MRT_GlobalFilterTextInput table={table} />
              <MRT_ToggleGlobalFilterButton table={table} />
              <MRT_ToggleFiltersButton table={table} />
              <MRT_ShowHideColumnsButton table={table} />
              <MRT_ToggleDensePaddingButton table={table} />
              <MRT_ToggleFullScreenButton table={table} />
              <Tooltip label="Print">
                <ActionIcon onClick={handlePrint}>
                  <IconPrinter size={18} />
                </ActionIcon>
              </Tooltip>
            </div>

          </div>
          <div className='flex justify-center items-center mx-1'>

            {/* Modal for custom date filter */}
            {renderDateFilter()}
            <button
              className={`rounded-full text-white mx-1 px-1`}
              onClick={handleGetReportClick} // Update this line
              style={{ height: '20px', width: '67px', fontSize: '10px', background: darkModeState.checkvalue ? dayTheme.ReportButtonColor : darkTheme.ReportButtonColor }}
            >
              {t('getreport')}
            </button>
          </div>
        </div>
      </Box>
    ),
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!selectedReport) {
    return <div>No report selected.</div>; // Handle case where no report is selected
  }



  return (
    <div className='w-[100%] sm:mt-[95px] lg:mt-[95px] xl:mt-[95px] shadow'>
      <MantineReactTable table={table} />




      {menuLoaded && <BeforeLoadTableData />}
      <Modal
        opened={printModalOpened}
        onClose={() => setPrintModalOpened(false)} // Close Modal
        size="xxl"
        title={selectedReport?.header?.name}
        closeOnClickOutside={false}
        withCloseButton={false}  // Removes the default close icon
        styles={{
          header: {
            background: 'linear-gradient(to right, #5fa1d6, #373195)',
            color: 'white',
          },
          content: {
            background: 'linear-gradient(to bottom, #ffffff, #d4d4d4)',
            borderRadius: '8px',
          },
        }}
      >
        <div id="print-area">
          <ReportPrintComponent
            reportData={allreportdata}
            template={selectedTemplate}
            totalRecords={totalRecords}
            onClose={() => setPrintModalOpened(false)}  // Close modal after printing
          />
        </div>
      </Modal>

      {hiddenReloadIcon && <BeforeLoadTableData />}
    </div>
  );
}

export default ReportMainCom;
