import { useEffect, useState, useRef } from "react";
import { Table, TextInput, NumberInput, Select, Button, Text, Modal } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { BaseURL} from "./masterPagefunctions";
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import { dayTheme, darkTheme } from "../../PageStyle/colorsdarkWhite";
import { useDispatch, useSelector } from 'react-redux';
import { setRows } from "../../Redux/Reducer/gridRowSlice";
import { clearRows } from "../../Redux/Reducer/gridRowSlice";
import { useTranslation } from 'react-i18next';
import BillAllocation from "../BillAllocation/BillAllocation";
import "./mastergrid.css"
import { setgridSelectOptions } from "../../Redux/Reducer/gridSelect";
import { setTaxRows } from "../../Redux/Reducer/taxRowSlice";
import useFetchTaxes from '../../Hook/usefetchtax';
import { processFormGrids } from "../../utils/footercal";
import { updateRowsWithTax } from "../../utils/footercal";
import api from "../../axiosInstance";




function MasterGrid({ gridfield, formDetails, accountList, setAccountList, setLogoutModalExRefreshToken }) {
    const { t } = useTranslation();

    const [rows, setRowsState] = useState([]);
    const [selectedRows, setSelectedRows] = useState([])
    const [selectOptions, setSelectOptions] = useState({});
    const [validationErrors, setValidationErrors] = useState({})
    const [activeRow, setActiveRow] = useState(null);
    const [headerWidths, setHeaderWidths] = useState({})
    const headerRefs = useRef([])
    const [responseData, setResponseData] = useState([])
    const [openModal, setOpenModal] = useState(false);  // State to control modal visibility
    const [txnId, setTxnId] = useState(null)
    const [modalSize, setModalSize] = useState('60%');
    const [modaltxn, setModalTxn] = useState(null)

    const inputRefs = useRef([])

    const dispatch = useDispatch();
    const darkModeState = useSelector((state) => state.darkMode)
    const selectState = useSelector((state) => state.selectMenu)
    const { fetchTaxes, arrtax } = useFetchTaxes(setLogoutModalExRefreshToken);


    console.log("rows", rows)

    useEffect(() => {
        processFormGrids(formDetails.form_grids, rows, fetchTaxes);
    }, [rows, dispatch])

    let uniqueData
    useEffect(() => {

        uniqueData = arrtax.filter((value, index, self) =>
            index === self.findIndex((t) => JSON.stringify(t) === JSON.stringify(value))

        );

        const newRows = updateRowsWithTax(rows, uniqueData);
        dispatch(setTaxRows(newRows));

    }, [uniqueData, rows, arrtax])


    const updateModalSize = () => {
        if (window.innerWidth < 768) {
            setModalSize('95%')
        }
        else {
            setModalSize('60%')
        }
    }

    useEffect(() => {
        updateModalSize();
        window.addEventListener('resize', updateModalSize)
        return () => {
            window.removeEventListener('resize', updateModalSize)
        }

    }, [])





    useEffect(() => {

        const initialRow = gridfield?.reduce((acc, item) => {
            acc[item.name] = item.initialvalue != null && item.initialvalue != undefined ? item.inputtype == "Number" ? Number(item.initialvalue) : item.initialvalue : "";
            return acc
        }, {});

        const fetchSelectOptions = async () => {
            const options = {}
            for (const field of gridfield) {
                if (field.inputtype === "Dropdown" && field.apiservicename) {
                    try {
                        let dropdownurl = ""
                        const { apiservicename, apiarguments, apiendpoint, apilabelkey, apidatakey } = field
                        if (apiarguments) {
                            dropdownurl = `https://${apiservicename}.${BaseURL}${apiendpoint}?${apiarguments}`
                        }
                        else {
                            dropdownurl = `https://${apiservicename}.${BaseURL}${apiendpoint}`
                        }


                        const response = await api.get(dropdownurl)
                        const transformeddata = Array.isArray(response?.data?.data[0]?.active) ?
                            response?.data?.data[0]?.active?.map((item) => ({ label: item[apilabelkey], value: item[apidatakey] })) :
                            response?.data?.data.map((item) => ({ label: item[apilabelkey], value: item[apidatakey] }))
                        console.log("transformeddata", transformeddata)
                        setResponseData(response?.data?.data)
                        setSelectOptions((prev) => ({
                            ...prev,
                            [field.name]: transformeddata

                        }))


                    }
                    catch (error) {
                        console.error(`Error fetching options for ${field.name}:`, error);
                        // Set empty or default options on error
                        options[field.name] = [];
                    }


                }

            }
            dispatch(setgridSelectOptions(options))
        }


        fetchSelectOptions();

        if (selectState.name == "Insert") {
            setRowsState([initialRow])

        }

        else {

            const { apiendpoint, apiservicename } = formDetails
            const url = `https://${apiservicename}.${BaseURL}${apiendpoint}?id=${selectState.userId}`;
            const fetchEditData = async () => {
                try {

                    const response = await api.get(url)

                    const viewData = response.data.data[0];

                    let getJsonField = formDetails.form_grids
                        .filter(item => item.jsonref) // Filter for objects where `jsonref` is not null
                        .map(item => ({ jsonref: item.jsonref, name: item.name })); // Extract `jsonref` and `name`

                    const dataKeys = Object.keys(initialRow)

                    if (viewData.transaccounts) {
                        const extractData = viewData.transaccounts.map(row => {
                            return dataKeys.reduce((obj, key) => {
                                const jsonField = getJsonField.find(field => field.name === key);


                                if (jsonField) {

                                    try {




                                        const parsedNarrations = JSON.parse(row[jsonField.jsonref]);

                                        obj[jsonField.jsonref] = parsedNarrations || "";



                                    }
                                    catch (err) {
                                        obj[key] = row[key]
                                    }

                                }

                                else if (row[key] !== undefined && !isNaN(row[key])) {
                                    obj[key] = Number(row[key])
                                }
                                else {
                                    obj[key] = row[key]
                                }
                                // console.log("extra",obj)
                                return obj
                            }, { txn_id: row.txn_id })


                        })


                        let result = extractData.some(item => 'txn_transsign' in item) ? extractData.map(item => ({
                            ...item,
                            txn_transsign: item.txn_transsign > 0 ? `+${item.txn_transsign}` : `${item.txn_transsign}`
                        })) : extractData

                        setRowsState(result)
                    }
                    else {

                        let getJsonField = formDetails.form_grids
                            .filter(item => item.jsonref) // Filter for objects where `jsonref` is not null
                            .map(item => ({ jsonref: item.jsonref, name: item.name })); // Extract `jsonref` and `name`
                        // console.log(" getJsonField", getJsonField)

                        const convertTranssignToString = (obj) => {
                            // console.log(" convertTranssignToString obj",obj)
                            if (obj.txn_transsign === undefined) {
                                return obj; // Return the object as-is if txn_transsign does not exist
                            }

                            return {
                                ...obj,
                                txn_transsign: obj.txn_transsign > 0 ? `+${obj.txn_transsign}` : `${obj.txn_transsign}`
                            };
                        };

                        const extractData = dataKeys.reduce((obj, key) => {
                            const jsonField = getJsonField.find(field => field.name === key);

                            if (jsonField) {
                                try {
                                    const parsedNarrations = JSON.parse(viewData[jsonField.jsonref]);

                                    obj[jsonField.jsonref] = parsedNarrations || "";
                                }
                                catch (error) {
                                    obj[key] = viewData[key]
                                }
                            }
                            if (viewData[key] !== undefined && !isNaN(viewData[key])) {
                                obj[key] = Number(viewData[key])
                            }
                            else {
                                obj[key] = viewData[key]
                            }
                            return obj
                        }, { txn_id: viewData.txn_id })


                        let result = convertTranssignToString(extractData)

                        setRowsState([result])
                    }

                }
                catch (error) {
                    console.error("Error fetching view data", error);
                }
            };
            fetchEditData();
        }



    }, [gridfield])

    useEffect(() => {
        dispatch(setRows(rows))
    }, [rows, dispatch])

    useEffect(() => {
        dispatch(clearRows(selectedRows))
    }, [selectedRows, dispatch])


    useEffect(() => {
        const widths = {};
        headerRefs.current.forEach((ref, index) => {
            if (ref) {
                widths[gridfield[index].name] = ref.offsetWidth;
            }
        });
        setHeaderWidths(widths)
    }, [gridfield])

    // const inputStyles = {
    //     input:{
    //         height:'10px',
    //         fontSize:'14px',
    //         width:'200px'

    //     },
    //     select:{
    //         height:'10px',
    //         fontSize:'14px',
    //         width:'200px'

    //     }
    // }

    const inputStyles = {
        input: (fieldName) => ({
            height: '10px',
            fontSize: '14px',
            width: headerWidths[fieldName] || '200px'
        }),
        select: (fieldName) => ({
            height: '10px',
            fontSize: '14px',
            width: headerWidths[fieldName] || '200px'
        })
    }

    const addRow = () => {

        if (selectState.name === "View" || selectState.name === "Delete") {
            return;
        }

        const newRow = gridfield?.reduce((acc, item) => {
            acc[item.name] = item.initialvalue != null && item.initialvalue != undefined ? item.inputtype == "Number" ? Number(item.initialvalue) : item.initialvalue : "";
            return acc
        }, {});

        if (selectState.name === "Update") {
            newRow.action = "new"
            // setSelectedRows(prevSelectedRows => [...prevSelectedRows, {...newRow,action:"new"}]);
        }


        setRowsState(prevRows => {
            const updatedRows = [...prevRows, newRow];

            // Ensure the setState callback is executed after the state update
            setTimeout(() => {
                const newRowIndex = updatedRows.length - 1; // Index of the new row
                const firstFieldIndex = 0; // Index of the first field
                const firstRef = inputRefs.current[newRowIndex * gridfield.length + firstFieldIndex];
                if (firstRef) {
                    firstRef.focus(); // Move cursor to the first field of the new row
                }
            }, 0);

            return updatedRows;
        });
    }

    const removeRow = (rowIndex) => {
        if (selectState.name === "View" || selectState.name === "Delete") {
            return;

        }

        const rowToDelete = rows[rowIndex]
        if (selectState.name == "Update") {
            const updatedRows = rows.filter((_, index) => index !== rowIndex)
            setSelectedRows((prevRows) => [...prevRows, { ...rowToDelete, action: "delete" }])
            setRowsState(updatedRows)
        }
        if (selectState.name == "Insert") {
            const updatedRows = rows.filter((_, index) => index !== rowIndex);
            setRowsState(updatedRows);
        }
    };

    // const handleInputChange = (rowIndex, fieldName, value) => {
    //     const field = gridfield.find(f =>f.name === fieldName);
    //     let newValue= value; 

    //     if(field.inputtype === 'Number'){
    //         const numericValue= newValue?.toString().match(/^\d*\.?\d*$/)?.[0];
    //         if(numericValue===undefined || numericValue!==newValue.toString()){
    //             setValidationErrors(prevErrors=>({
    //                 ...prevErrors,
    //                 [rowIndex]:{
    //                     ...prevErrors[rowIndex],
    //                     [fieldName]: t("Please enter a valid number.")
    //                 }

    //             }));
    //             return;
    //         }
    //         else {
    //             setValidationErrors(prevError=>({
    //                 ...prevError,
    //                 [rowIndex]:{
    //                     ...prevError[rowIndex],
    //                     [fieldName]:false
    //                 }

    //             }));
    //         }
    //         newValue=parseFloat(numericValue);
    //     }


    //     const updatedRows = rows.map((row, index) => {
    //         console.log("updatedRows row",row)
    //         console.log("updatedRows field",field)
    //         // if (index === rowIndex) {

    //         //     if(field.jsonref){
    //         //         return {...row, [field.jsonref]:{[fieldName]:newValue}}
    //         //     }
    //         //     return { ...row, [fieldName]: value };
    //         // }
    //         // return row;
    //         if (index === rowIndex) {
    //             if (field.jsonref) {
    //                 // Handle updating the nested jsonref object
    //                 return {
    //                     ...row,
    //                     [field.jsonref]: {
    //                         ...row[field.jsonref],
    //                         [fieldName]: newValue
    //                     }
    //                 };
    //             }
    //             // Default update for fields without jsonref
    //             return { ...row, [fieldName]: newValue };
    //         }
    //         return row;
    //     });

    //     const updateEditRow = updatedRows[rowIndex] 
    //     setRowsState(updatedRows);
    //     if (selectState.name === "Update") {
    //         setSelectedRows(prevSelectedRows => {
    //             const rowToUpdate = prevSelectedRows.find(row => row.txn_id === rows[rowIndex].txn_id);

    //             if (rowToUpdate) {
    //                 // Update the existing row in `selectedRows`
    //                 return prevSelectedRows.map(row =>
    //                     row.txn_id === rows[rowIndex].txn_id
    //                         ? {
    //                             ...row,
    //                             ...(field.jsonref
    //                                 ? {
    //                                     [field.jsonref]: {
    //                                         ...row[field.jsonref],
    //                                         [fieldName]: newValue
    //                                     }
    //                                 }
    //                                 : { [fieldName]: newValue }
    //                             )
    //                         }
    //                         : row
    //                 );
    //             } else {
    //                 // Add the new row to `selectedRows`
    //                 return [
    //                     ...prevSelectedRows,
    //                     {
    //                         ...rows[rowIndex],
    //                         ...(field.jsonref
    //                             ? {
    //                                 [field.jsonref]: {
    //                                     ...rows[rowIndex][field.jsonref],
    //                                     [fieldName]: newValue
    //                                 }
    //                             }
    //                             : { [fieldName]: newValue }
    //                         )
    //                     }
    //                 ];
    //             }
    //         });
    //     }

    //     validateRow(updatedRows[rowIndex], rowIndex);
    // };

    const handleInputChange = (rowIndex, fieldName, value) => {
        const field = gridfield.find(f => f.name === fieldName);
        let newValue = value;

        // if (field.inputtype === 'Number') {
        //     const numericValue = newValue?.toString().match(/^\d*\.?\d*$/)?.[0];
        //     if (numericValue === undefined || numericValue !== newValue.toString()) {
        //         setValidationErrors(prevErrors => ({
        //             ...prevErrors,
        //             [rowIndex]: {
        //                 ...prevErrors[rowIndex],
        //                 [fieldName]: t("Please enter a valid number.")
        //             }

        //         }));
        //         return;
        //     }
        //     else {
        //         setValidationErrors(prevError => ({
        //             ...prevError,
        //             [rowIndex]: {
        //                 ...prevError[rowIndex],
        //                 [fieldName]: false
        //             }

        //         }));
        //     }
        //     newValue = parseFloat(numericValue);
        // }

        const updatedRows = rows.map((row, index) => {


            // Check if this is the row being updated
            if (index === rowIndex) {
                let updatedRow = row;

                // Handle updating the nested jsonref object
                if (field.jsonref) {
                    updatedRow = {
                        ...row,
                        [field.jsonref]: {
                            ...row[field.jsonref],
                            [fieldName]: newValue
                        }
                    };
                } else {
                    // Default update for fields without jsonref
                    updatedRow = { ...row, [fieldName]: newValue };
                }

                // Check if selectState.name is "Update" and add action: "onchange"
                if (selectState.name === "Update") {
                    updatedRow = {
                        ...updatedRow,
                        modified: "onchange"
                    };
                }

                if (fieldName === 'txn_accid' && newValue) {
                    setTxnId(newValue)
                    setModalTxn(rows[rowIndex])
                    if (formDetails.billallocation != "Auto") {
                        setOpenModal(true)
                    }
                }

                return updatedRow;
            }
            return row;
        });


        // const updatedRows = rows.map((row, index) => {
        //     // console.log("updatedRows row",row)
        //     // console.log("updatedRows field",field)
        //     // if (index === rowIndex) {

        //     //     if(field.jsonref){
        //     //         return {...row, [field.jsonref]:{[fieldName]:newValue}}
        //     //     }
        //     //     return { ...row, [fieldName]: value };
        //     // }
        //     // return row;
        //     if (index === rowIndex) {
        //         if (field.jsonref) {
        //             // Handle updating the nested jsonref object
        //             return {
        //                 ...row,
        //                 [field.jsonref]: {
        //                     ...row[field.jsonref],
        //                     [fieldName]: newValue
        //                 }
        //             };
        //         }
        //         // Default update for fields without jsonref
        //         return { ...row, [fieldName]: newValue };
        //     }
        //     return row;
        // });

        // console.log("updatedRows rowss",updatedRows)
        const updateEditRow = updatedRows[rowIndex]
        setRowsState(updatedRows);



        // if (selectState.name === "Update") {
        //     setSelectedRows(prevSelectedRows => {
        //         const rowToUpdate = prevSelectedRows.find(row => row.txn_id === rows[rowIndex].txn_id);

        //         if (rowToUpdate) {
        //             // Update the existing row in `selectedRows`
        //             return prevSelectedRows.map(row =>
        //                 row.txn_id === rows[rowIndex].txn_id
        //                     ? {
        //                         ...row,
        //                         ...(field.jsonref
        //                             ? {
        //                                 [field.jsonref]: {
        //                                     ...row[field.jsonref],
        //                                     [fieldName]: newValue
        //                                 }
        //                             }
        //                             : { [fieldName]: newValue }
        //                         )
        //                     }
        //                     : row
        //             );
        //         } else {
        //             // Add the new row to `selectedRows`
        //             return [
        //                 ...prevSelectedRows,
        //                 {
        //                     ...rows[rowIndex],
        //                     ...(field.jsonref
        //                         ? {
        //                             [field.jsonref]: {
        //                                 ...rows[rowIndex][field.jsonref],
        //                                 [fieldName]: newValue
        //                             }
        //                         }
        //                         : { [fieldName]: newValue }
        //                     )
        //                 }
        //             ];
        //         }
        //     });
        // }


        validateRow(updatedRows[rowIndex], rowIndex);
    };




    const validateRow = (row, index) => {
        const errors = {}
        gridfield.forEach(field => {
            if (field.required && !row[field.name]) {
                errors[field.name] = true
            }
            if (field.inputtype === "Number" && field.required && row[field.name] == 0) {
                // errors[field.name] = `${field.label} ${t('cannotBeZeroOrEmpty')}`;
                errors[field.name] = true

            }
            if (field.inputtype === "Number" && field.required && row[field.name] == 0) {
                // errors[field.name] = `${field.label} cannot be zero or empty.`;
                errors[field.name] = true
            }
            if (field.inputtype === "Number" && field.maxvalue !== null && row[field.name] > field.maxvalue) {
                errors[field.name] = `${field.label} cannot be more than ${field.maxvalue}.`;
            }

        })
        setValidationErrors(prevErrors => ({ ...prevErrors, [index]: errors }));
    }


    const areAllFieldsFilled = (row) => {
        return gridfield.every(field => {
            return !field.required || (field.required && row[field.name]);
        })
    }

    const handleKeyDown = (index, event, fieldName) => {
        const isLastColumn = gridfield[gridfield.length - 1].name === fieldName;

        if (event.key === 'Enter') {
            event.preventDefault();


            const currentField = gridfield.find(field => field.name === fieldName);
            if (currentField.required && !rows[index][fieldName]) {
                validateRow(rows[index], index); // Show validation errors
                const currentRef = inputRefs.current[index * gridfield.length + gridfield.findIndex(field => field.name === fieldName)];
                if (currentRef) {
                    currentRef.focus(); // Focus on the current required field
                }
                return;
            }


            if (isLastColumn) {

                const isValid = areAllFieldsFilled(rows[index]);

                if (!isValid) {
                    validateRow(rows[index], index); // Show validation errors
                    return; // Prevent moving to the next field or adding a new row
                }

                window.dispatchEvent(new Event('rowsUpdated'));


                // Check if a new row has already been added
                setActiveRow(index);

                // Add a new row only if the current active row is the last row
                if (isValid && index === rows.length - 1) {

                    addRow()

                }
                // dispatch(setReduxRows(rows));


                // Move cursor to the first field of the new row
                const newRowIndex = rows.length;
                const firstFieldIndex = 0;
                const firstRef = inputRefs.current[newRowIndex * gridfield.length + firstFieldIndex];
                if (firstRef) {
                    firstRef.focus(); // Move cursor to the first field of the new row
                }
            } else {
                // Move to the next field in the current row
                const nextFieldIndex = gridfield.findIndex(field => field.name === fieldName) + 1;
                if (nextFieldIndex < gridfield.length) {
                    const nextRef = inputRefs.current[index * gridfield.length + nextFieldIndex];
                    if (nextRef) {
                        nextRef.focus();
                    }
                }
            }
        }
    };

    // let arr=[...rows]
    // const filteredRows = selectState.name == "Update"?
    // arr.sort((a,b)=>a.txn_id - b.txn_id):rows

    const handleClickValue = (field) => {
        setAccountList({ accSeleBox: responseData, accSelectFileds: field })
    }

    const handleConfirmUpdate = (newAmount, billData) => {
        const finalbilldata = billData.map(item => ({
            billid: item.billid,
            amount: item.settledamount
        }))
        const updatedRows = rows.map(row => {
            if (row.txn_accid === txnId) {
                return {
                    ...row,
                    txn_amount: newAmount,
                    txn_billinfo: finalbilldata
                };
            }
            return row;
        })
        setRowsState(updatedRows);
        const updatedRowIndex = rows.findIndex(row => row.txn_accid == txnId)
        validateRow(updatedRows[updatedRowIndex], updatedRowIndex)
        setOpenModal(false)
    }




    return (
        <>
            {/* <Button onClick={addRow} variant="light" style={{ marginTop: '10px' }}>
                    Add Row
                </Button> */}
            <div style={{ overflowX: "auto", overflowY: "auto", minHeight: "100px", maxWidth: '100%', marginTop: "-10px" }}>
                <Table style={{ minWidth: '100%' }}>
                    <thead>
                        <tr>
                            {
                                gridfield.map((field) => (
                                    <th key={field.id} style={{ textAlign: 'left', paddingLeft: '24px' }}>{field.label}</th>
                                ))
                            }
                            <th style={{ textAlign: 'left', paddingLeft: '24px' }}>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            rows?.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {
                                        gridfield.map((field, colIndex) => (
                                            <td key={field.id} >
                                                {
                                                    field.inputtype === "Text" && (
                                                        <TextInput
                                                            size="sm"

                                                            // value={row[field.name]}
                                                            value={field.jsonref ? row[field.jsonref]?.[field.name] || '' : row[field.name] || ''}
                                                            onChange={(e) => handleInputChange(rowIndex, field.name, e.target.value)}
                                                            onKeyDown={(event) => handleKeyDown(rowIndex, event, field.name)}
                                                            ref={el => inputRefs.current[rowIndex * gridfield.length + colIndex] = el}
                                                            rightSection={validationErrors[rowIndex]?.[field.name] && <CheckCircleOutlineRoundedIcon sx={{ color: 'red' }} />}
                                                            disabled={selectState.name === "View" || selectState.name === "Delete"}


                                                        />
                                                    )
                                                }
                                                {
                                                    field.inputtype === "Number" && (
                                                        <>
                                                            <NumberInput
                                                                size="sm"

                                                                hideControls
                                                                value={row[field.name]}
                                                                onChange={(value) => handleInputChange(rowIndex, field.name, value)}
                                                                onKeyDown={(event) => handleKeyDown(rowIndex, event, field.name)}
                                                                ref={el => inputRefs.current[rowIndex * gridfield.length + colIndex] = el}
                                                                rightSection={validationErrors[rowIndex]?.[field.name] && <CheckCircleOutlineRoundedIcon sx={{ color: 'red' }} />}
                                                                classNames={{
                                                                    input: 'number-input-value',
                                                                }}
                                                                disabled={selectState.name === "View" || selectState.name === "Delete"}
                                                                decimalSeparator="."  // Extract this from `dataformat`
                                                                thousandsSeparator="," // Extract this from `dataformat`
                                                                formatter={(value) =>
                                                                    value
                                                                        ? value.replace(/\B(?=(\d{3})+(?!\d))/g, ",") // Format thousands separator
                                                                        : ""
                                                                }
                                                                parser={(value) =>
                                                                    value.replace(/\$\s?|(,*)/g, "") // Parse formatted input back to number
                                                                }
                                                                precision={field.decimalplaces}





                                                            />

                                                            {validationErrors[rowIndex]?.[field.name] && (
                                                                <Text color="red" size="xs" mt="4px">
                                                                    {validationErrors[rowIndex][field.name]}
                                                                </Text>
                                                            )}
                                                        </>
                                                    )
                                                }
                                                {
                                                    field.inputtype === 'Dropdown' && (
                                                        <Select
                                                            styles={(theme) => ({
                                                                item: {
                                                                    '&[data-hovered], &[data-selected]': {
                                                                        backgroundColor: darkModeState.checkvalue ? dayTheme.LabelBarColor : darkTheme.LabelBarColor,
                                                                        color: "black",
                                                                    },
                                                                },
                                                            })}

                                                            data={selectOptions[field.name] || []}
                                                            defaultValue=""
                                                            value={row[field.name]}
                                                            onChange={(value) => handleInputChange(rowIndex, field.name, value)}
                                                            onKeyDown={(event) => handleKeyDown(rowIndex, event, field.name)}
                                                            ref={el => inputRefs.current[rowIndex * gridfield.length + colIndex] = el}
                                                            rightSection={validationErrors[rowIndex]?.[field.name] && <CheckCircleOutlineRoundedIcon sx={{ color: "red" }} />}
                                                            disabled={selectState.name === "View" || selectState.name === "Delete"}
                                                            searchable
                                                            onClick={() => handleClickValue(field)}


                                                        />
                                                    )
                                                }
                                                {
                                                    field.inputtype === "Date" && (
                                                        <DateInput

                                                            value={row[field.name]}
                                                            onChange={(value) => handleInputChange(rowIndex, field.name, value)}
                                                            ref={el => inputRefs.current[rowIndex * gridfield.length + colIndex] = el}
                                                            onKeyDown={(event) => handleKeyDown(rowIndex, event, field.name)} // Add this line
                                                            rightSection={validationErrors[rowIndex]?.[field.name] && <CheckCircleOutlineRoundedIcon sx={{ color: "red" }} />}
                                                            disabled={selectState.name === "View" || selectState.name === "Delete"}




                                                        />
                                                    )
                                                }



                                            </td>

                                        ))
                                    }
                                    <td>
                                        {rows?.length > 1 && (
                                            <Button
                                                variant="outline"
                                                color="red"
                                                onClick={() => removeRow(rowIndex)}
                                                size="xs"
                                            >
                                                Remove
                                            </Button>
                                        )}
                                    </td>

                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </div>
            <Modal
                opened={openModal}
                onClose={() => setOpenModal(false)}
                size={modalSize}
                className="modalBillCustom"
            >
                <BillAllocation
                    txnId={txnId}
                    modaltxn={modaltxn}
                    onConfirm={handleConfirmUpdate}

                />
            </Modal>
        </>
    )
}

export default MasterGrid