import axios from 'axios';
import { BaseURL } from './Components/Masters/masterPagefunctions';


export const refreshToken = async () => {
    try {
        const responseObject = JSON.parse(sessionStorage.getItem("responseobjerct"));

        if (!responseObject) {
            throw new Error("No session object found");
        }

        const accessTokenExpiresAt = responseObject?.access_token_expires_at.split(" ")[1];
        const refreshTokenExpiresAt = responseObject?.refresh_token_expires_at.split(" ")[1];
        const refresh_token = responseObject?.refresh_token;
        // const now = new Date();
        // now.setSeconds(0, 0);
        const currentTime = new Date().toUTCString().split(" ")[4];
        console.log("accessTokenExpiresAt", accessTokenExpiresAt);
        console.log("currentTime", currentTime);

        // Check access token expiration
        if (currentTime < accessTokenExpiresAt) {
            return responseObject.access_token; // Access token is still valid
        }

        // Check refresh token expiration
        if (currentTime >= refreshTokenExpiresAt) {
            console.log("Refresh token expired. Logging out...");
            sessionStorage.clear();
            window.location.href = '/'
            // Handle logout scenario properly
            return null;
        }

        // Refresh token is valid, proceed to get a new access token
        const orgid = sessionStorage.getItem("orgid");
        const email = sessionStorage.getItem("email");

        const refreshResponse = await axios.post(
            `https://userhub.${BaseURL}/api/v1/refresh-token`,
            { refresh_token, orgid, email }
        );

        const { data } = refreshResponse;

        // Store the new tokens in sessionStorage
        sessionStorage.setItem("responseobjerct", JSON.stringify(data));
        sessionStorage.setItem("token", data.access_token);

        return data.access_token;
    } catch (error) {
        console.error("Token refresh failed:", error);
        return null; // Ensure it doesn't break the interceptor
    }
};

// Create Axios instance
const api = axios.create();
api.interceptors.request.use(
    async (config) => {
        let token = sessionStorage.getItem("token");
        token = await refreshToken();
        const email = sessionStorage.getItem("email");

        config.headers.Authorization = `Bearer ${token}`;
        config.headers.identifier = `${email}`;

        return config;
    },
    (error) => Promise.reject(error)
);

export default api;
