import React, { useEffect, useState } from "react";
import PieChart from "./PieChart";
import LineChart from "./LineChart";
import BarChart from "./BarChart";
import Dashboard from './dashoard.json';
import cash from '../../asset/DashboardIcon/img/icons/1.svg'
import sale from '../../asset/DashboardIcon/img/icons/2.svg'
import purchase from '../../asset/DashboardIcon/img/icons/3.svg'
import expense from '../../asset/DashboardIcon/img/icons/4.svg'
import bagroundimage from '../../asset/DashboardIcon/img/1.jpg'
import bagroundimagetwo from '../../asset/DashboardIcon/img/2.jpg'
import bagroundimagethree from '../../asset/DashboardIcon/img/3.jpg'
import BeforeLoadTableData from "../CommanCoponent/BeforeLoadTableData";
import DashboardCard from "./DashboardCard";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import api from "../../axiosInstance";
import { BaseURL } from "../Masters/masterPagefunctions";


function MasterDashBoard({ hiddenReloadIcon, }) {


    const queryClient = useQueryClient()

    const fetchDashboard = async () => {
        const userid = sessionStorage.getItem("userid");
        const { data } = await api.get(`https://dashboardhub.${BaseURL}/api/v1/dashboards/${userid}`);
        return data; // Fix the response structure
    };

    const { data, error, isLoading, dataUpdatedAt } = useQuery({
        queryKey: ["dashboardData"],
        queryFn: fetchDashboard,
        refetchInterval: () => {
            const cachedData = queryClient.getQueryData(["dashboardData"]); // Access last known data
            const refreshInterval = cachedData?.data?.dashboards?.[0]?.refreshinterval;
            return refreshInterval ? refreshInterval * 1000 : false;
        },
    });



    const [dashboardfilterId, setdashboardfilterId] = useState(null);

    useEffect(() => {
        if (data?.data?.dashboards?.length > 0) {
            setdashboardfilterId(data?.data?.dashboards?.[0]?.id); // Set first dashboard's ID
        }
    }, [data]);





    const lastRefetchTimeParent = dataUpdatedAt
        ? new Date(dataUpdatedAt).toLocaleTimeString()
        : "No refetch yet";




    if (isLoading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;
    const refreshInterval = data?.data?.dashboards?.[0]?.refreshinterval ?? 0;
    console.log(refreshInterval);
   

    // console.log("dashboard data", data);



    const dashboardImage = [
        { name: "Cash", image: cash, backgroundimage: bagroundimage, color: '#71c285' }, { name: "Sales", image: sale, backgroundimage: bagroundimage, color: '#bec62b' }, { name: "Purchase", image: purchase, backgroundimage: bagroundimage, color: '#42a5f5' }, { name: "EXPENSE", image: expense, backgroundimage: bagroundimage, color: '#9b6050' }, { name: "Unsetteld Debit", image: null, backgroundimage: bagroundimagetwo }, { name: "Unsetteld Credit", image: null, backgroundimage: bagroundimagethree }
    ]






    return (
        <div className="mt-[100px]">
           
            {data?.data?.dashboards?.length !== 1 ? data?.data?.dashboards?.map((button) => (
                <button onClick={() => setdashboardfilterId(button?.id)} className={`mx-3 my-1 ${button?.id === dashboardfilterId ? 'text-black border-b-2 border-stone-500' : 'text-stone-500'} `} style={{ fontSize: '12px', fontWeight: 600 }}>{button?.name}</button>
            )) : ''}
            <div
                className={`${data?.data?.dashboards?.length !== 1 ? 'h-[75vh] overflow-y-auto p-3' : 'h-[80vh] overflow-y-auto p-3' }`}
                style={{
                    display: "grid",
                    gridTemplateColumns: `repeat(${data?.data?.dashboards?.[0]?.columncount}, minmax(100px, 1fr))`, // Responsive columns
                    gridAutoRows: "minmax(100px, auto)", // Flexible row heights
                    gap: `${Dashboard.dashboard.columngap}rem`, // Grid gap
                }}
            >

                {data?.data?.dashboards?.filter((filter) => filter?.id === dashboardfilterId)?.[0]?.widgets
                    .sort((a, b) => a.position - b.position)
                    .map((item) => {
                        const bgimg = dashboardImage.find((bgimg) => bgimg.name === item.name);
                        return (
                            <div
                                key={item.position}
                                style={{
                                    gridColumn: `span ${item.columnspan}`,
                                    gridRow: `span ${item.rowspan}`,
                                    backgroundImage: bgimg ? `url(${bgimg.backgroundimage})` : "none",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    backgroundRepeat: "no-repeat",
                                }}
                                className="bg-white rounded shadow text-gray-900 text-lg font-bold"
                            >
                                {item?.widgettype === "PieChart" ? (
                                    <PieChart item={item} lastRefetchTimeParentms={dataUpdatedAt} />
                                ) : item?.widgettype === "LineChart" ? (
                                    <LineChart item={item} lastRefetchTimeParentms={dataUpdatedAt} />
                                ) : item?.widgettype === "BarChart" ? (
                                    <BarChart item={item} lastRefetchTimeParentms={dataUpdatedAt} />
                                ) : item?.widgettype === "Card" ? (
                                    <DashboardCard item={item} bgimage={bgimg} lastRefetchTimeParentms={dataUpdatedAt} />
                                ) : <div> hhh</div>}
                            </div>
                        );


                    })}
                {hiddenReloadIcon && <BeforeLoadTableData />}
            </div>
            <h6 className="text-gray-[#676767] m-1" style={{ fontSize: '10px', fontWeight: "600" }}>Last Update : {lastRefetchTimeParent}</h6>
        </div>

    );
}

export default MasterDashBoard;
