import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";
import { BaseURL } from "../Masters/masterPagefunctions";
import api from "../../axiosInstance";
import { useQuery } from "@tanstack/react-query";
import syncimage from '../../asset/sync.png'

const PieChart = ({ item, lastRefetchTimeParentms }) => {
    const chartRef = useRef(null);

    const fetchDashboardPiechart = async () => {
        const { data } = await api.post(`https://${item?.apiservicename}.${BaseURL}${item?.apiendpoint}`, {
            userid: 1,
            api: item?.apiendpoint,
            apiargument: item?.apiarguments?.[0],
        });
        return data;
    };

    const { data, error, isLoading, isFetching, dataUpdatedAt, refetch } = useQuery({
        queryKey: ["dashboardPiechartValues", item?.apiendpoint, item?.apiarguments?.[0]],
        queryFn: fetchDashboardPiechart,
        // refetchInterval: item?.refreshinterval,
        // refetchOnWindowFocus: true, 
    });



    const lastRefetchTimeChild = dataUpdatedAt
        ? new Date(dataUpdatedAt).toLocaleTimeString()
        : "No refetch yet";

    const diffrents = lastRefetchTimeParentms - dataUpdatedAt

    let trueconsition
    if (item?.autorefresh) {
        trueconsition = diffrents > item?.refreshinterval * 1000
    }

    useEffect(() => {
        refetch()
    }, [trueconsition])




    useEffect(() => {
        if (!data || !chartRef.current) return;

        const chartInstance = echarts.init(chartRef.current);

        const chartData = Array.isArray(data?.data) && data.data.length > 0
            ? data.data.map((items) => ({
                value: items[item?.yvalues?.[0]?.toLowerCase()],
                name: items[item?.xvalues?.[0]?.toLowerCase()],
            }))
            : [{ value: 1, name: "No Data" }];

        const option = {
            title: {
                text: item?.name || "Pie Chart",
                left: "center",
            },
            tooltip: {
                trigger: "item",
            },
            legend: {
                orient: "vertical",
                right: "right",
                top: "center",
            },
            series: [
                {
                    name: item?.name || "Data",
                    type: "pie",
                    radius: ['60%', '100%'],
                    center: ['40%', '90%'],
                    startAngle: 180,
                    endAngle: 360,
                    data: chartData,
                    label: { show: false, position: "center" },
                    color: ["#8067dc", "#a267db"],
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: "rgba(0, 0, 0, 0.5)",
                        },
                        // label: {
                        //     show: true,
                        //     fontSize: 40,
                        //     fontWeight: 'bold'
                        // }
                    },
                },
            ],
        };

        chartInstance.setOption(option);

        const handleResize = () => chartInstance.resize();
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
            chartInstance.dispose();
        };
    }, [data]);

    if (isLoading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    return (<div className="pt-3" style={{ height: '100%' }}><div ref={chartRef} style={{ width: "100%", height: "85%" }} />
        <div className='flex justify-end items-center px-1'>
        {item?.showsynctime && <label className='p-1' style={{ fontSize: '12px' }}>Syn Time : {lastRefetchTimeChild}</label>} {item?.allowsync && <button style={{ fontSize: '10px' }} onClick={() => refetch()}> <img src={syncimage} className={isFetching ? 'animate-spin' : ''} alt="" srcset="" width={15} /> </button>}
        </div>
    </div>);
};

export default PieChart;
