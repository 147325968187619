import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";
import { useQuery } from "@tanstack/react-query";
import api from "../../axiosInstance"; // Ensure correct import
import { BaseURL } from "../Masters/masterPagefunctions"; // Ensure BaseURL is imported
import syncimage from '../../asset/sync.png'

const BarChart = ({ item, lastRefetchTimeParentms }) => {
  const chartRef = useRef(null);

  // Fetch data from API
  const fetchDashboardBarchart = async () => {
    const { data } = await api.post(
      `https://${item?.apiservicename}.${BaseURL}${item?.apiendpoint}`,
      {
        userid: 1,
        api: item?.apiendpoint,
        apiargument: item?.apiarguments?.[0],
      }
    );

    // Transform data into required format
    return {
      name: data?.data?.map((d) => d[item?.xvalues?.[0]?.toLowerCase()]) || [],
      values: data?.data?.map((d) => parseFloat(d[item?.yvalues?.[0]?.toLowerCase()])) || [],
    };
  };

  const { data, error, isLoading, isFetching, dataUpdatedAt, refetch } = useQuery({
    queryKey: ["dashboardBarchartValues", item?.apiendpoint, item?.apiarguments?.[0]],
    queryFn: fetchDashboardBarchart,
    // refetchInterval: item?.refreshinterval,
    // refetchOnWindowFocus: true, 
  });



  const lastRefetchTimeChild = dataUpdatedAt
    ? new Date(dataUpdatedAt).toLocaleTimeString()
    : "No refetch yet";

  const diffrents = lastRefetchTimeParentms - dataUpdatedAt

  let trueconsition
  if (item?.autorefresh) {
    trueconsition = diffrents > item?.refreshinterval * 1000
  }

  useEffect(() => {
    refetch()
  }, [trueconsition])

  useEffect(() => {
    if (!data || !chartRef.current) return;

    const chartInstance = echarts.init(chartRef?.current);
    const maxVal = Math.max(...(Array.isArray(data?.values) && data.values.length > 0 ? data.values : [0]));


    const option = {
      title: {
        text: item?.name || "Bar Chart",
        left: "center",
      },
      backgroundColor: "white",
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },

      grid: {
        left: "5%",
        right: "5%",
        bottom: "10%",
        containLabel: true,
      },
      xAxis: {
        type: "category",
        data: data.name,
        axisLabel: { color: "#333" },
        axisLine: { lineStyle: { color: "#43418e" } },
      },
      yAxis: {
        type: "value",
        min: 0,
        max: maxVal,
        interval: maxVal / 5,
        splitLine: { lineStyle: { type: "dashed", color: "black" } },
        axisLabel: {
          color: "#666",
          formatter: function (value) {
            if (value >= 1e9) {
              return (value / 1e9).toFixed(1) + 'B';
            } else if (value >= 1e6) {
              return (value / 1e6).toFixed(1) + 'M';
            } else if (value >= 1e3) {
              return (value / 1e3).toFixed(1) + 'K';
            }
            return value;
          },
        },
        axisLine: { lineStyle: { color: "#999" } },
      },
      series: [
        {
          data: data.values,
          type: "bar",
          barWidth: "50%",
          itemStyle: {
            color: function (params) {
              const colors = ["#35c1da", "#ea3d37", "#9cb72e", "#f5932e", "#a07ab8"];
              return colors[params.dataIndex % colors.length];
            },
            borderRadius: [5, 5, 0, 0],
          },
          emphasis: {
            itemStyle: { color: "#cfe3fc" },
          },
        },
      ],
    };

    chartInstance.setOption(option);
    window.addEventListener("resize", chartInstance.resize);

    return () => {
      window.removeEventListener("resize", chartInstance.resize);
      chartInstance.dispose();
    };
  }, [data]);

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return <div className="pt-3" style={{ height: '100%' }}><div ref={chartRef} style={{ width: "100%", height: "85%" }} />
    <div className='flex justify-end items-center px-1'>
    {item?.showsynctime && <label className='p-1' style={{ fontSize: '12px' }}>Syn Time : {lastRefetchTimeChild}</label>} {item?.allowsync && <button style={{ fontSize: '10px' }} onClick={() => refetch()}> <img src={syncimage} className={isFetching ? 'animate-spin' : ''} alt="" srcset="" width={15} /> </button>}
    </div>
  </div>;
};

export default BarChart;
