import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";
import { useQuery } from "@tanstack/react-query";
import api from "../../axiosInstance";
import { BaseURL } from "../Masters/masterPagefunctions";
import syncimage from '../../asset/sync.png'

const LineChart = ({ item, lastRefetchTimeParentms }) => {
  const chartRef = useRef(null);

  // Fetch data from API
  const fetchDashboardLineChart = async () => {
    const { data } = await api.post(
      `https://${item?.apiservicename}.${BaseURL}${item?.apiendpoint}`,
      {
        userid: 1,
        api: item?.apiendpoint,
        apiargument: item?.apiarguments?.[0],
      }
    );

    // Transform data into required format
    return {
      name: data?.data?.map((d) => d[item?.xvalues?.[0]?.toLowerCase()]) || [],
      values: data?.data?.map((d) => parseFloat(d[item?.yvalues?.[0]?.toLowerCase()])) || [],
    };
  };

  const { data, error, isLoading, isFetching, dataUpdatedAt, refetch } = useQuery({
    queryKey: ["dashboardLineChartValues", item?.apiendpoint, item?.apiarguments?.[0], item?.refreshinterval],
    queryFn: fetchDashboardLineChart,
    // refetchInterval: item?.refreshinterval * 1000,
    // refetchOnWindowFocus: true, 
  });


  const lastRefetchTimeChild = dataUpdatedAt
    ? new Date(dataUpdatedAt).toLocaleTimeString()
    : "No refetch yet";

  const diffrents = lastRefetchTimeParentms - dataUpdatedAt

  let trueconsition

  if (item?.autorefresh) {
    trueconsition = diffrents > item?.refreshinterval * 1000
  }

  useEffect(() => {
    refetch()
  }, [trueconsition])

  useEffect(() => {
    if (!data || !chartRef.current) return;

    // Initialize chart
    const myChart = echarts.init(chartRef.current);

    // Chart configuration
    const option = {
      title: {
        text: `${item.name}`,
        left: "center",
      },
      tooltip: {
        trigger: "item",
      },
      xAxis: {
        type: "category",
        data: data.name,
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          data: data.values,
          type: "line",
          smooth: true,
        },
      ],
    };

    // Set the options
    myChart.setOption(option);

    // Handle window resize
    const handleResize = () => myChart.resize();
    window.addEventListener("resize", handleResize);

    // Cleanup on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
      myChart.dispose();
    };
  }, [data]); // Trigger the effect when data changes

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div className="pt-3" style={{ height: '100%' }}>
      <div
        ref={chartRef}
        style={{ width: "100%", height: "95%" }} // Set fixed height
      />
      <div className='flex justify-end items-center px-1'>
      {item?.showsynctime && <label className='p-1' style={{ fontSize: '12px' }}>Syn Time : {lastRefetchTimeChild}</label>} {item?.allowsync && <button style={{ fontSize: '10px' }} onClick={() => refetch()}> <img src={syncimage} className={isFetching ? 'animate-spin' : ''} alt="" srcset="" width={15} /> </button>}
      </div>
    </div>
  );
};

export default LineChart;
